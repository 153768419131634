import React from 'react';
import cntl from 'cntl';

const mobileClassName = cntl`
  px-2
  pt-2
  pb-3
  space-y-1
`;

const desktopClassName = cntl`
  hidden
  md:block
  md:ml-10
  md:space-x-10
`;

interface NavProps {
  mobile: boolean;
}

const Nav: React.FC<NavProps> = ({ mobile, children }) => {
  return (
    <div className={mobile ? mobileClassName : desktopClassName}>
      {children}
    </div>
  );
};

export default Nav;
