import React, { Fragment } from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import Brand from './brand';
import HeaderMobile from './mobile';
import NavItem from '../nav/item';
import Nav from '../nav/nav';
import CTA from './cta';
import { NavigationItem } from '../../@types';
import { classNames } from '../../utils';

const popoverWrapperClassName = cntl`
  relative
  max-w-7xl
  flex
  items-center
  justify-between
  px-4
  sm:px-6
`;

const leftContainerClassName = cntl`
  flex
  items-center
  flex-1
`;

const brandWrapperClassName = cntl`
  flex
  items-center
  justify-between
  w-full
  md:w-auto
`;

const popoverButtonWrapperClassName = cntl`
  -mr-2
  flex
  items-center
  md:hidden
`;

const popoverButtonClassName = cntl`
  rounded-md
  p-2
  inline-flex
  items-center
  justify-center
  text-gray-400
  hover:text-gray-500
  hover:bg-gray-100
  focus:outline-none
  focus:ring-2
  focus:ring-inset
  focus:ring-primary-500
`;

const menuIconClassName = cntl`
  h-6
  w-6
`;

const popoverPanelClassName = cntl`
  absolute
  top-0
  inset-x-0
  p-2
  transition
  transform
  origin-top-right
  md:hidden
  z-50
`;

interface HeaderProps {
  navigation: Array<NavigationItem>;
  hasMainCTA: boolean;
  phoneNumber: string;
  isCentered?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  navigation,
  hasMainCTA,
  isCentered,
  phoneNumber,
}) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <nav
            className={classNames(
              popoverWrapperClassName,
              isCentered ? 'mx-auto' : ''
            )}
            aria-label="Global"
          >
            <div className={leftContainerClassName}>
              <div className={brandWrapperClassName}>
                <Link to="/">
                  <span className="sr-only">SmartWaxPro</span>
                  <Brand mobile={false} />
                </Link>
                <div className={popoverButtonWrapperClassName}>
                  <Popover.Button className={popoverButtonClassName}>
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon
                      className={menuIconClassName}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>
              </div>
              <Nav mobile={false}>
                {navigation.map(item => (
                  <NavItem
                    isInnerLink={item.isInnerLink}
                    mobile={false}
                    href={item.href}
                    key={item.name}
                  >
                    {item.name}
                  </NavItem>
                ))}
              </Nav>
            </div>
            <CTA
              phoneNumber={phoneNumber}
              hasMainCTA={hasMainCTA}
              mobile={false}
            />
          </nav>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel focus static className={popoverPanelClassName}>
              <HeaderMobile
                phoneNumber={phoneNumber}
                hasMainCTA={hasMainCTA}
                navigation={navigation}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Header;
