import React from 'react';
import cntl from 'cntl';
import brandSVG from '../../assets/brand.svg';
import useSiteMetadata from '../../hooks/useSiteMetadata';

const imageClassName = cntl`
  h-8
  w-auto
`;

interface BrandProps {
  mobile: boolean;
}

const Brand: React.FC<BrandProps> = ({ mobile }) => {
  const { site } = useSiteMetadata();

  return (
    <img
      className={`${imageClassName} ${!mobile ? 'sm:h-10' : ''}`}
      src={brandSVG}
      alt={site.siteMetadata.title}
    />
  );
};

export default Brand;
