import React from 'react';
import cntl from 'cntl';
import { Popover } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Brand from './brand';
import NavItem from '../nav/item';
import Nav from '../nav/nav';
import CTA from './cta';
import { NavigationItem } from '../../@types';

interface MobileProps {
  navigation: NavigationItem[];
  hasMainCTA: boolean;
  phoneNumber: string;
}

const wrapperClassName = cntl`
  rounded-lg
  shadow-md
  bg-white
  ring-1
  ring-black
  ring-opacity-5
  overflow-hidden
`;

const topContainerClassName = cntl`
  px-5
  pt-4
  flex
  items-center
  justify-between
`;

const popoverButtonWrapperClassName = cntl`
  -mr-2
`;

const popoverButtonClassName = cntl`
  bg-white
  rounded-md
  p-2
  inline-flex
  items-center
  justify-center
  text-gray-400
  hover:text-gray-500
  hover:bg-gray-100
  focus:outline-none
  focus:ring-2
  focus:ring-inset
  focus:ring-primary-500
`;

const popoverCloseIconClassName = cntl`
  h-6
  w-6
`;

const Mobile: React.FC<MobileProps> = ({
  navigation,
  hasMainCTA,
  phoneNumber,
}) => {
  return (
    <div className={wrapperClassName}>
      <div className={topContainerClassName}>
        <div>
          <Brand mobile />
        </div>
        <div className={popoverButtonWrapperClassName}>
          <Popover.Button className={popoverButtonClassName}>
            <span className="sr-only">Close main menu</span>
            <XIcon className={popoverCloseIconClassName} aria-hidden="true" />
          </Popover.Button>
        </div>
      </div>
      <Nav mobile>
        {navigation.map(item => (
          <NavItem
            isInnerLink={item.isInnerLink}
            mobile
            href={item.href}
            key={item.name}
          >
            {item.name}
          </NavItem>
        ))}
      </Nav>
      <CTA phoneNumber={phoneNumber} hasMainCTA={hasMainCTA} mobile />
    </div>
  );
};

export default Mobile;
