import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';

interface NavItemProps {
  href: string;
  mobile: boolean;
  isInnerLink: boolean;
}

const desktopClassName = cntl`
  font-medium
  text-gray-500
  hover:text-gray-900
`;

const mobileClassName = cntl`
  block
  px-3
  py-2
  rounded-md
  text-base
  font-medium
  text-gray-700
  hover:text-gray-900
  hover:bg-gray-50
`;

const NavItem: React.FC<NavItemProps> = ({
  href,
  mobile,
  children,
  isInnerLink = false,
}) => {
  if (isInnerLink) {
    return (
      <Link to={href} className={mobile ? mobileClassName : desktopClassName}>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} className={mobile ? mobileClassName : desktopClassName}>
      {children}
    </a>
  );
};

export default NavItem;
