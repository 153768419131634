import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/useSiteMetadata';

interface MetaObject {
  name?: string;
  property?: string;
  content: string;
}

interface SEOProps {
  title: string;
  description?: string;
  lang?: string;
  useTitleTemplate?: boolean;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description = '',
  lang = 'en',
  useTitleTemplate = true,
}) => {
  const { site } = useSiteMetadata();

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title;

  const metaTags: MetaObject[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={useTitleTemplate ? `%s - ${defaultTitle}` : `%s`}
    >
      {metaTags.map((metaTag: MetaObject, index: number) => {
        if (typeof metaTag.name === undefined) {
          return (
            <meta
              key={index}
              property={metaTag.property}
              content={metaTag.content}
            />
          );
        }

        return (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        );
      })}
    </Helmet>
  );
};

export default SEO;
