import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/gatsby';
import { RequestDocument } from 'graphql-request/dist/types';
import { RuntimeQueryHookState } from '../../@types';
import contentfulClient from '../client/contentful';
import isRuntime from '../is-runtime';

export default function useContentful<T>(
  document: RequestDocument,
  dynamicShouldFetch = true
): RuntimeQueryHookState<T> {
  const [state, setState] = useState<RuntimeQueryHookState<T>>({
    loading: false,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (!isRuntime() || !dynamicShouldFetch) {
      return;
    }

    (async () => {
      setState(state => ({ ...state, loading: true }));

      try {
        const data = await contentfulClient<T>(document);
        setState(state => ({ ...state, data }));
      } catch (err) {
        setState(state => ({
          ...state,
          error: true,
        }));
        Sentry.captureException(err);
      } finally {
        setState(state => ({ ...state, loading: false }));
      }
    })();
  }, [document, dynamicShouldFetch]);

  return state;
}
