import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';
import { PhoneIcon, ChatIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils';

interface SignInProps {
  mobile: boolean;
  hasMainCTA: boolean;
  phoneNumber: string;
}

const desktopClassName = cntl`
  hidden
  md:flex
  items-center
  justify-end
  md:flex-1
  lg:w-0
`;

const mobileClassName = cntl`
  px-5
  pt-4
  pb-4
`;

const desktopCTAClassName = cntl`
  ml-8
  whitespace-nowrap
  inline-flex
  items-center
  justify-center
  px-4
  py-2
  border
  border-transparent
  rounded-md
  shadow-sm
  text-base
  font-medium
  text-white
  bg-primary-600
  hover:bg-primary-700
`;

const mobileCTAClassName = cntl`
  w-full
  flex
  items-center
  justify-center
  px-4
  py-2
  border
  border-transparent
  rounded-md
  shadow-sm
  text-base
  font-medium
  text-white
  bg-primary-600
  hover:bg-primary-700
`;

const phoneNumberClassName = cntl`
  font-bold
  text-gray-700
  flex
  justify-center
  items-center
`;

const phoneNumberIconsClassName = cntl`
  h-5
  w-5
  mx-1
`;

const mobilePhoneNumberClassName = cntl`
  text-center
  block
  w-full
  mb-5
`;

const CTA: React.FC<SignInProps> = ({ mobile, hasMainCTA, phoneNumber }) => {
  const cleanPhoneNumber = `+1${phoneNumber.replace(/[(|)|\-|\s]+/g, '')}`;

  return (
    <div className={mobile ? mobileClassName : desktopClassName}>
      <a
        href={`tel:${cleanPhoneNumber}`}
        className={classNames(
          phoneNumberClassName,
          mobile ? mobilePhoneNumberClassName : ''
        )}
      >
        <PhoneIcon className={phoneNumberIconsClassName} />
        <ChatIcon className={phoneNumberIconsClassName} />
        {phoneNumber}
      </a>
      {mobile ? (
        <>
          {hasMainCTA && (
            <Link to="/booking" className={mobileCTAClassName}>
              Book now
            </Link>
          )}
        </>
      ) : (
        <>
          {hasMainCTA && (
            <Link to="/booking" className={desktopCTAClassName}>
              Book now
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default CTA;
