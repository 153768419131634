import { GraphQLClient } from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';

const environment = 'master';
const endpoint = 'https://graphql.contentful.com/content/v1';
const SPACE = process.env.GATSBY_CONTENTFUL_SPACE_ID;
const BASE_URL = `${endpoint}/spaces/${SPACE}/environments/${environment}`;

export default function contentfulClient<T>(
  document: RequestDocument
): Promise<T> {
  const token = process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN;
  const authorization = `Bearer ${token}`;
  const graphQLClient = new GraphQLClient(BASE_URL, {
    headers: { authorization },
  });

  return graphQLClient.request(document);
}
